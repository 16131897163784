<template>
    <div class="PostbackLogs">

        <Loading
            v-if="!isPostbackLogsLoaded && isPostbackIdValid"
        ></Loading>

        <Alert
            addclass="mt-20"
            v-if="!isPostbackIdValid"
            :message="$lng.__('lk_webmaster_errors', 'Postback ID not defined')"
        ></Alert>

        <Card v-if="isPostbackIdValid && isPostbackLogsLoaded">

            <div
                v-if="!postbackLogs.length"
                class="PostbackLogs__empty">
                {{ $lng.__('lk_webmaster_main', 'No results found.') }}
            </div>

            <div
                v-if="postbackLogs.length"
                class="PostbackLogs__table">
                <div class="table__wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ $lng.__('lk_webmaster_main', 'Date') }}</th>
                                <th>{{ $lng.__('lk_webmaster_main', 'Request') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="postbackLog in postbackLogs">
                                <td>
                                    <div class="PostbackLogs__table__date">
                                        {{ postbackLog.created_ts.split(' ')[0] }}
                                    </div>
                                    <div class="PostbackLogs__table__time">
                                        {{ postbackLog.created_ts.split(' ')[1] }}
                                    </div>
                                </td>
                                <td>
                                    <div class="PostbackLogs__table__url">
                                        <strong>URL:</strong> {{ postbackLog.url }}
                                    </div>

                                    <div
                                        v-if='postbackLog.response !== ""'
                                        class="PostbackLogs__table__response"
                                        v-html="postbackLog.response.replaceAll('\n', '<br>')">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>

    </div>
</template>

<script>

export default {
    name: 'PostbackLogs',
    props: [],
    data() {
        return {
            isLoading: false,
            isPostbackLogsLoaded: false,
        };
    },
    computed: {
        postbackId() {
            return WV.getUrlParamByName('postback_id');
        },
        isPostbackIdValid() {
            return this.postbackId !== null;
        },
        postbackLogs() {
            return this.$store.state.webmaster.postbackLogs;
        },

    },
    methods: {},
    beforeMount() {
        if (this.isPostbackIdValid) {
            this.$store.dispatch('webmaster/getPostbackLogs', this.postbackId)
                .then(() => {
                    this.isPostbackLogsLoaded = true;
                });
        }

    },
    components: {}
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.PostbackLogs {
    width: 100%;
    margin-top: 100px;
    @include break(md) {
        margin-top: 0;
    }
    &__table {
        margin: -30px;
        font-family: $font-secondary;
        thead {
            tr {
                th {
                    text-align: left;
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: top;
                }
            }
        }
        &__date {
            white-space: nowrap;
            font-size: 15px;
            font-weight: bold;
        }
        &__time {
            padding-top: 5px;
            font-size: 14px;
            color: $color-text-muted;
        }
        &__url {
            font-size: 14px;
            font-weight: bold;
        }
        &__response {
            margin-top: 15px;
            font-family: monospace;
            font-size: 14px;
            background: $color-day-table;
            border-radius: 5px;
            padding: 15px 20px;
            line-height: 120%;
            min-width: 320px;
        }
    }
    &__empty {
        font-weight: 600;
        color: $color-text-muted;
    }
}

</style>